/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

const PageContext = createContext({});

const PageContextProvider = ({ data, children }) => {
	const [alias, setAlias] = useState();
	const [aliasLoading, setAliasLoading] = useState(true);

	return (
		<PageContext.Provider value={{ ...data, alias, setAlias, aliasLoading, setAliasLoading }}>
			{children}
		</PageContext.Provider>
	);
};

PageContextProvider.propTypes = {
	data: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default PageContext;
export { PageContextProvider, PageContext };
