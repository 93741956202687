import { useContext } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { RCLContext } from "./RCLProvider";

const RCL = ({ searchKey, replace }) => {
	const { locale, dictionary } = useContext(RCLContext);
	try {
		const item = dictionary[locale][searchKey];

		let replacedValue = item.value;
		if (replace) {
			for (let i = 0; i < Object.entries(replace).length; i++) {
				const [key, value] = Object.entries(replace)[i];
				replacedValue = item.value.replace(`{{ ${key} }}`, value);
			}
		}

		if (item.parseAsMarkdown) {
			return parse(replacedValue);
		}
		return replacedValue;
	} catch {
		if (process.env.NODE_ENV === "development") {
			console.warn(`Cannot find key '${searchKey}' in the dictionary`);
		}
		return null;
	}
};

RCL.propTypes = {
	searchKey: PropTypes.string.isRequired,
	replace: PropTypes.object,
};

export default RCL;
