// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-cruise-results-index-js": () => import("./../../../src/templates/cruise-results/index.js" /* webpackChunkName: "component---src-templates-cruise-results-index-js" */),
  "component---src-templates-destinations-country-js": () => import("./../../../src/templates/destinations/country.js" /* webpackChunkName: "component---src-templates-destinations-country-js" */),
  "component---src-templates-destinations-destination-js": () => import("./../../../src/templates/destinations/destination.js" /* webpackChunkName: "component---src-templates-destinations-destination-js" */),
  "component---src-templates-faqs-landing-js": () => import("./../../../src/templates/faqs/landing.js" /* webpackChunkName: "component---src-templates-faqs-landing-js" */),
  "component---src-templates-faqs-qa-js": () => import("./../../../src/templates/faqs/qa.js" /* webpackChunkName: "component---src-templates-faqs-qa-js" */),
  "component---src-templates-faqs-search-js": () => import("./../../../src/templates/faqs/search.js" /* webpackChunkName: "component---src-templates-faqs-search-js" */),
  "component---src-templates-fluid-js": () => import("./../../../src/templates/fluid.js" /* webpackChunkName: "component---src-templates-fluid-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-hotel-brand-js": () => import("./../../../src/templates/hotel-brand.js" /* webpackChunkName: "component---src-templates-hotel-brand-js" */),
  "component---src-templates-timed-data-js": () => import("./../../../src/templates/timed-data.js" /* webpackChunkName: "component---src-templates-timed-data-js" */),
  "component---src-templates-travel-alerts-index-js": () => import("./../../../src/templates/travel-alerts/index.js" /* webpackChunkName: "component---src-templates-travel-alerts-index-js" */),
  "component---src-templates-ui-js": () => import("./../../../src/templates/ui.js" /* webpackChunkName: "component---src-templates-ui-js" */)
}

