/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from "react";
import * as dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import { AlertsProvider } from "./src/context";
import { PageContextProvider } from "./src/components/PageContext";
import { RCLProvider } from "./src/components/RCL";
import "./src/styles/index.scss";

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
	const lang = props?.pageContext?.locale?.substring(0, 2);
	dayjs.locale(lang);

	return (
		<PageContextProvider data={{ ...props?.pageContext, lang }}>
			<AlertsProvider
				lang={props?.pageContext?.locale}
				staticAlertsCount={props?.pageContext?.numberOfAlerts}
				timestamp={props?.pageContext?.timestamp}
			>
				<RCLProvider lang={props?.pageContext?.locale}>{element}</RCLProvider>
			</AlertsProvider>
		</PageContextProvider>
	);
};
