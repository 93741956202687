import React, { useState, useEffect, createContext, useContext } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import useInterval from "../../utils/useInterval";

dayjs.extend(utc);

const AlertsContext = createContext({
	hasLiveData: false,
	hasMadeApiCall: false,
	alerts: {
		activeAlerts: [],
		expiredAlerts: [],
	},
	alertsCount: 0,
	refreshAlerts: () => {},
	localizeTimestamp: () => {},
	latestTimestamp: "",
});

const useAlerts = () => useContext(AlertsContext);

const AlertsProvider = ({ children, lang, staticAlertsCount, timestamp }) => {
	const localizeTimestamp = (locale, dateTime) => {
		const format = locale === "fr-CA" ? "DD MMMM YYYY [à] HH [h] mm" : "MMMM Do, YYYY [at] h:mm A";
		const localTimeText = locale === "fr-CA" ? "Heure locale" : "Local time";
		return dayjs(dateTime || undefined)
			.locale(locale.slice(0, 2))
			.format(`${format} ([${localTimeText}])`);
	};

	const [loading, setIsLoading] = useState(true);
	const [hasLiveData, setHasLiveData] = useState(false);
	const [hasMadeApiCall, setHasMadeApiCall] = useState(false);
	const [alerts, setAlerts] = useState({});
	const [alertsCount, setAlertsCount] = useState(staticAlertsCount);
	const [latestTimestamp, setLatestTimestamp] = useState();

	useEffect(() => {
		setIsLoading(false);
		setLatestTimestamp(localizeTimestamp(lang, timestamp));
	}, [loading]);

	const refreshAlerts = async () => {
		setHasMadeApiCall(false);
		setHasLiveData(false);
		try {
			const response = await fetch(`/api/travel-alerts?lang=${lang}`);
			const res = await response.json();
			setAlerts(res);
			setAlertsCount(res?.activeAlerts?.length ?? 0);
			setHasLiveData(true);
			setLatestTimestamp(localizeTimestamp(lang));
		} catch (error) {
			console.error("Error", error);
			setAlertsCount(staticAlertsCount);
		}
		setHasMadeApiCall(true);
	};

	const visibilityCallback = () => {
		if (document.visibilityState === "visible") {
			(async () => {
				await refreshAlerts();
			})();
		}
	};

	useEffect(() => {
		if (!loading) {
			refreshAlerts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, loading]);

	useEffect(() => {
		if (!loading) {
			// Check the session on window focus
			window.addEventListener("visibilitychange", visibilityCallback);
		}
		return () => {
			window.removeEventListener("visibilitychange", visibilityCallback);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useInterval(refreshAlerts, 5 * 60 * 1000);

	const value = {
		alerts,
		alertsCount,
		refreshAlerts,
		latestTimestamp,
		localizeTimestamp,
		hasLiveData,
		hasMadeApiCall,
	};

	return <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>;
};

AlertsProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
	lang: PropTypes.string,
	staticAlertsCount: PropTypes.number.isRequired,
	timestamp: PropTypes.number.isRequired,
};

AlertsProvider.defaultProps = {
	lang: "en-CA",
};

export { AlertsProvider, useAlerts };
